import { useEffect, useMemo, useState } from 'react'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'

import { ButtonGroupComponent } from './components/ButtonGroupComponent'
import { identify, Identify, track, init } from '@amplitude/analytics-browser'

import { Page1 } from './components/Page1'
import { Page2 } from './components/Page2'
import { Page3 } from './components/Page3'
import { Page4 } from './components/Page4'
import { getSafetyInformationFromURL } from './utils/getSafetyInformationFromURL'
import { Header } from './components/Header'
import { DownloadApp } from './components/DownloadApp'
import { CurrentLocation } from './components/CurrentLocation'
import { SafetyInformation } from './types'

const isUserURL = window.location.href.includes('/user')

const App = () => {
  const [showAppDownloadBanner, setShowAppDownloadBanner] = useState(true)

  useEffect(() => {
    if (isUserURL) {
      return
    }

    const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY

    init(amplitudeApiKey!)
    const identifyObj = new Identify()
    identify(identifyObj)
    track('Read info on web')
  }, [])

  const safetyInformation = useMemo(() => {
    if (isUserURL) {
      return {} as SafetyInformation
    }

    return getSafetyInformationFromURL(window.location.href)
  }, [])

  const fullName =
    safetyInformation?.fullName ||
    `${safetyInformation?.firstName} ${safetyInformation?.lastName}`

  const onClick = () => {
    setShowAppDownloadBanner(false)
  }

  const [page, setPage] = useState(0)

  return (
    <>
      {!isUserURL && (
        <Box mb={2} width="100vw">
          <Header
            fullName={fullName}
            nativeLanguage={safetyInformation?.language1Name}
            secondaryLanguage={safetyInformation?.language2Name}
            birthDate={safetyInformation?.birthDate}
            lastEdited={safetyInformation?.updatedAt}
          />

          <Stack
            padding={3}
            spacing={3}
            bgcolor={'white'}
            borderRadius={'20px'}
            marginTop={'-20px'}
          >
            <CurrentLocation />
            <ButtonGroupComponent onClick={setPage} page={page} />
            {page === 0 && <Page1 safetyInformation={safetyInformation} />}
            {page === 1 && <Page2 safetyInformation={safetyInformation} />}
            {page === 2 && <Page3 safetyInformation={safetyInformation} />}
            {page === 3 && <Page4 safetyInformation={safetyInformation} />}
          </Stack>
        </Box>
      )}

      <DownloadApp visible={showAppDownloadBanner} onClick={onClick} />
    </>
  )
}

export default App
