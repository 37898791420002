import Button from '@mui/material/Button'

export const ButtonComponent = (props: any) => {
  const { isActive, text, onClick } = props

  return (
    <Button
      sx={{
        borderRadius: '50px!important',
        fontSize: 10,
        py: 2,
        fontFamily: 'Aeonik-Bold',
        backgroundColor: isActive
          ? 'var(--color-main-pitch-black)!important'
          : 'transparent',
        color: isActive ? '#fff' : 'var(--color-main-pitch-black)',
      }}
      onClick={() => onClick(0)}
      key="one"
      color="primary"
      variant={'text'}
      disableRipple
    >
      {text}
    </Button>
  )
}
