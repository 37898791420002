import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const EyeRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
        {...props}
      >
        <path
          d="M12 16c1.25 0 2.313-.437 3.188-1.312S16.5 12.75 16.5 11.5c0-1.25-.437-2.313-1.312-3.188S13.25 7 12 7c-1.25 0-2.313.437-3.188 1.312S7.5 10.25 7.5 11.5c0 1.25.437 2.313 1.312 3.188S10.75 16 12 16Zm0-1.8c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 0 1 9.3 11.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0 1 12 8.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0 1 12 14.2Zm0 4.8c-2.317 0-4.433-.612-6.35-1.837-1.917-1.225-3.367-2.88-4.35-4.963a.813.813 0 0 1-.1-.313 2.919 2.919 0 0 1 0-.775.81.81 0 0 1 .1-.312c.983-2.083 2.433-3.737 4.35-4.962C7.567 4.613 9.683 4 12 4c2.317 0 4.433.613 6.35 1.838 1.917 1.225 3.367 2.879 4.35 4.962.05.083.083.187.1.312a2.936 2.936 0 0 1 0 .775.813.813 0 0 1-.1.313c-.983 2.083-2.433 3.738-4.35 4.963C16.433 18.388 14.317 19 12 19Zm0-2a9.545 9.545 0 0 0 5.188-1.488A9.77 9.77 0 0 0 20.8 11.5a9.777 9.777 0 0 0-3.612-4.013A9.55 9.55 0 0 0 12 6a9.55 9.55 0 0 0-5.188 1.487A9.777 9.777 0 0 0 3.2 11.5a9.77 9.77 0 0 0 3.612 4.012A9.545 9.545 0 0 0 12 17Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
