import ButtonGroup from '@mui/material/ButtonGroup'
import { Stack } from '@mui/system'
import { ButtonComponent } from './Button'

export const ButtonGroupComponent = (props: any) => {
  const { page, onClick } = props

  return (
    <Stack direction="row">
      <ButtonGroup
        size="large"
        sx={{
          borderRadius: '50px',
          backgroundColor: 'var(--color-main-mist)',
          padding: 1,
        }}
        fullWidth
      >
        <ButtonComponent
          isActive={page === 0}
          text="PERSONAL"
          onClick={() => onClick(0)}
        />
        <ButtonComponent
          isActive={page === 1}
          text="CONTACT"
          onClick={() => onClick(1)}
        />
        <ButtonComponent
          isActive={page === 2}
          text="DOCS"
          onClick={() => onClick(2)}
        />
        <ButtonComponent
          isActive={page === 3}
          text="EQUIPMENT"
          onClick={() => onClick(3)}
        />
      </ButtonGroup>
    </Stack>
  )
}
