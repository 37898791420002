import { expandPayloadV2 } from './expandPayloadV2'
import { expandPayloadV3 } from './expandPayloadV3'

/**
 * Utility function to extract safety information from URL string
 * @param url URL string
 * @returns SafetyInformationV3
 */
export const getSafetyInformationFromURL = (urlString: string) => {
  const url = new URL(urlString)
  const version = url.searchParams.get('v')

  if (version === '3') {
    return expandPayloadV3(url.searchParams)
  } else {
    return expandPayloadV2(urlString)
  }
}
