import Stack from '@mui/material/Stack'

import { DisplayText } from './DisplayText'
import { SafetyInformation } from '../types'
import { DateRounded } from '../assets/icons/DateRounded'
import { InfoRounded } from '../assets/icons/InfoRounded'
import { WorkRounded } from '../assets/icons/WorkRounded'
import { SafetyStandardRounded } from '../assets/icons/SafetyStandardRounded'
import { EmployerRounded } from '../assets/icons/EmployerRounded'

interface PageProps {
  safetyInformation: SafetyInformation
}

export const Page4 = ({ safetyInformation }: PageProps) => {
  return (
    <Stack margin={3} spacing={3}>
      <DisplayText
        icon={InfoRounded}
        title={'NAME'}
        value={[safetyInformation.name]}
      />

      <DisplayText
        icon={WorkRounded}
        title={'PRODUCT NUMBER'}
        value={[safetyInformation.productNumber]}
      />
      {Boolean(safetyInformation.industrial) && (
        <DisplayText
          icon={SafetyStandardRounded}
          title={'SAFETY STANDARD'}
          value={[safetyInformation.safetyStandard]}
        />
      )}
      {Boolean(safetyInformation.industrial) && (
        <DisplayText
          icon={EmployerRounded}
          title={'EMPLOYER'}
          value={[safetyInformation.employer]}
        />
      )}

      <DisplayText
        icon={DateRounded}
        title={'FIRST DATE OF USAGE'}
        value={[safetyInformation.dateFirstUsage]}
      />
    </Stack>
  )
}
