import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const LatitudeRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.125 21.212A9.671 9.671 0 0 0 12 22a9.64 9.64 0 0 0 3.887-.788 10.164 10.164 0 0 0 3.175-2.15 10.125 10.125 0 0 0 2.15-3.187A9.671 9.671 0 0 0 22 12a9.64 9.64 0 0 0-.788-3.887 10.164 10.164 0 0 0-2.15-3.175 10.178 10.178 0 0 0-3.175-2.151A9.65 9.65 0 0 0 12 2a9.681 9.681 0 0 0-3.875.787 10.14 10.14 0 0 0-3.187 2.151 10.164 10.164 0 0 0-2.15 3.175A9.64 9.64 0 0 0 2 12a9.67 9.67 0 0 0 .788 3.875 10.125 10.125 0 0 0 2.15 3.187c.908.909 1.97 1.625 3.187 2.15ZM7.65 14h-3.4A8.012 8.012 0 0 1 4 12a8.012 8.012 0 0 1 .25-2H19.75a8.018 8.018 0 0 1 .25 2 8.018 8.018 0 0 1-.25 2H7.65Zm6.25 2H5.1a8.304 8.304 0 0 0 1.812 2.175c.725.617 2.488 1.375 2.488 1.375s.267.117.6.2a8.018 8.018 0 0 0 2 .25 8.018 8.018 0 0 0 2-.25c.333-.083.6-.2.6-.2a7.19 7.19 0 0 0 2.487-1.375A8.295 8.295 0 0 0 18.9 16h-5Zm5-8H5.1a8.303 8.303 0 0 1 1.812-2.175A7.2 7.2 0 0 1 9.4 4.45s.267-.117.6-.2A8.012 8.012 0 0 1 12 4a8.012 8.012 0 0 1 2 .25c.333.083.6.2.6.2a7.19 7.19 0 0 1 2.487 1.375A8.294 8.294 0 0 1 18.9 8Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
