import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const LongitudeRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.125 21.212A9.671 9.671 0 0 0 12 22a9.64 9.64 0 0 0 3.887-.788 10.164 10.164 0 0 0 3.175-2.15 10.125 10.125 0 0 0 2.15-3.187A9.671 9.671 0 0 0 22 12a9.64 9.64 0 0 0-.788-3.887 10.164 10.164 0 0 0-2.15-3.175 10.178 10.178 0 0 0-3.175-2.151A9.65 9.65 0 0 0 12 2a9.681 9.681 0 0 0-3.875.787 10.14 10.14 0 0 0-3.187 2.151 10.164 10.164 0 0 0-2.15 3.175A9.64 9.64 0 0 0 2 12a9.67 9.67 0 0 0 .788 3.875 10.125 10.125 0 0 0 2.15 3.187c.908.909 1.97 1.625 3.187 2.15Zm.488-3.375c.225.592.487 1.163.787 1.713a7.2 7.2 0 0 1-2.488-1.375A8.304 8.304 0 0 1 5.1 16c-.45-.774-.76-1.639-.85-2A8.012 8.012 0 0 1 4 12a8.012 8.012 0 0 1 .25-2c.1-.4.493-1.384.85-2a8.303 8.303 0 0 1 1.812-2.175A7.2 7.2 0 0 1 9.4 4.45c-.3.55-.562 1.12-.787 1.712A14.656 14.656 0 0 0 8.05 8l-.4 2a13.365 13.365 0 0 0-.15 2 13.365 13.365 0 0 0 .15 2l.4 2c.15.633.338 1.246.563 1.837ZM12 19.95c.433-.6.808-1.225 1.125-1.875.317-.65.575-1.342.775-2.075l.45-2a13.35 13.35 0 0 0 .15-2 13.35 13.35 0 0 0-.15-2l-.45-2c-.2-.733-.458-1.425-.775-2.075A12.701 12.701 0 0 0 12 4.05c-.433.6-.808 1.225-1.125 1.875-.317.65-.575 1.342-.775 2.075l-.45 2a13.584 13.584 0 0 0-.15 2 13.018 13.018 0 0 0 .15 2l.45 2c.2.733.458 1.425.775 2.075.317.65.692 1.275 1.125 1.875Zm5.087-1.775A7.19 7.19 0 0 1 14.6 19.55c.3-.55.563-1.121.788-1.713.225-.591.412-1.204.562-1.837l.4-2a13.6 13.6 0 0 0 .15-2 13.033 13.033 0 0 0-.15-2l-.4-2a14.758 14.758 0 0 0-.562-1.838A13.922 13.922 0 0 0 14.6 4.45a7.19 7.19 0 0 1 2.487 1.375A8.294 8.294 0 0 1 18.9 8c.497.857.756 1.624.85 2a8.018 8.018 0 0 1 .25 2 8.018 8.018 0 0 1-.25 2c-.103.41-.367 1.167-.85 2a8.295 8.295 0 0 1-1.813 2.175Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
