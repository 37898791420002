interface Language {
  name: string
  native: string
}

export interface CountriesDropdownItem {
  flagId: string
  title: string
  countryCode: string
  countryCodeValue: string
  langs: Language[]
}

export type CountriesDropdownItems = CountriesDropdownItem[]

export interface CountryCodeDropdownProps {
  items: Array<CountriesDropdownItem | string>
  option: CountriesDropdownItem
  setOption: (option: any) => void
  read?: boolean
}

export interface LanguagesDropdownItem {
  name: string
  native: string
}

export enum MetricSystem {
  'metric' = 'cm/kg',
  'imperial' = 'ft/lbs',
}

export interface SafetyInformationV2 {
  updatedAt: string

  firstName: string
  lastName: string
  gender: string
  birthDate: string
  religion: string

  nationalityOption: CountriesDropdownItem
  languageOption1: LanguagesDropdownItem
  languageOption2: LanguagesDropdownItem
  languageOption3: LanguagesDropdownItem

  medicalHistory: string
  pacemaker: boolean
  organDonor: boolean
  identificationCharacteristics: string
  eyeColor: string
  height: string
  weight: string
  metric: MetricSystem | string

  emergencyContactName1: string
  emergencyContactRelation1: string
  emergencyContactPhone1: {
    countryInfo: CountriesDropdownItem
    number: string
  }

  emergencyContactName2: string
  emergencyContactRelation2: string
  emergencyContactPhone2: {
    countryInfo: CountriesDropdownItem
    number: string
  }

  emergencyContactName3: string
  emergencyContactRelation3: string
  emergencyContactPhone3: {
    countryInfo: CountriesDropdownItem
    number: string
  }

  passportNumber: string
  insuranceNumber: string
  insuranceCompany: string
  insuranceCompanyPhone: {
    countryInfo: CountriesDropdownItem
    number: string
  }

  dateFirstUsage: string
  name: string
  productNumber: string
  safetyStandard: string
}

export interface SafetyInformationV3 {
  updatedAt: string
  fullName: string
  firstName: string
  lastName: string
  gender: string
  birthDate: string
  religion: string

  nationalityCountryFlagId: string // V2.nationalityOption.flagId
  nationalityCountryCodeValue: string // V2.nationalityOption.countryCodeValue
  nationalityCountryTitle: string // V2.nationalityOption.title
  nationalityCountryCode: string // V2.nationalityOption.countryCode
  // V2.nationalityOption.langs
  nationalityCountryLangs: Language[] // V2.nationalityOption.langs

  language1Name: string // V2.languageOption1.name
  language1Native: string // V2.languageOption1.native

  language2Name: string // V2.languageOption2.name
  language2Native: string // V2.languageOption2.native

  language3Name: string // V2.languageOption3.name
  language3Native: string // V2.languageOption3.native

  medicalHistory: string
  pacemaker: boolean | null
  organDonor: boolean | null
  identificationCharacteristics: string
  eyeColor: string
  height: string
  weight: string
  metric: MetricSystem | string

  emergencyContact1Name: string // V2.emergencyContactName1
  emergencyContact1Relation: string // V2.emergencyContactRelation1
  emergencyContact1PhoneNumber: string // V2.emergencyContactPhone1.number
  emergencyContact1PhoneCountryFlagId: string // V2.emergencyContactPhone1.countryInfo.flagId
  emergencyContact1PhoneCountryCodeValue: string // V2.emergencyContactPhone1.countryInfo.countryCodeValue
  emergencyContact1PhoneCountryTitle: string // V2.emergencyContactPhone1.countryInfo.title
  emergencyContact1PhoneCountryCode: string // V2.emergencyContactPhone1.countryInfo.countryCode
  emergencyContact1PhoneCountryLangs: Language[] // V2.emergencyContactPhone1.countryInfo.langs

  emergencyContact2Name: string // V2.emergencyContactName2
  emergencyContact2Relation: string // V2.emergencyContactRelation2
  emergencyContact2PhoneNumber: string // V2.emergencyContactPhone2.number
  emergencyContact2PhoneCountryFlagId: string // V2.emergencyContactPhone2.countryInfo.flagId
  emergencyContact2PhoneCountryCodeValue: string // V2.emergencyContactPhone2.countryInfo.countryCodeValue
  emergencyContact2PhoneCountryTitle: string // V2.emergencyContactPhone2.countryInfo.title
  emergencyContact2PhoneCountryCode: string // V2.emergencyContactPhone2.countryInfo.countryCode
  emergencyContact2PhoneCountryLangs: Language[] // V2.emergencyContactPhone2.countryInfo.langs

  emergencyContact3Name: string // V2.emergencyContactName3
  emergencyContact3Relation: string // V2.emergencyContactRelation3
  emergencyContact3PhoneNumber: string // V2.emergencyContactPhone3.number
  emergencyContact3PhoneCountryFlagId: string // V2.emergencyContactPhone3.countryInfo.flagId
  emergencyContact3PhoneCountryCodeValue: string // V2.emergencyContactPhone3.countryInfo.countryCodeValue
  emergencyContact3PhoneCountryTitle: string // V2.emergencyContactPhone3.countryInfo.title
  emergencyContact3PhoneCountryCode: string // V2.emergencyContactPhone3.countryInfo.countryCode
  emergencyContact3PhoneCountryLangs: Language[] // V2.emergencyContactPhone3.countryInfo.langs

  passportNumber: string
  insuranceNumber: string
  insuranceCompany: string
  insuranceCompanyPhoneNumber: string // V2.insuranceCompanyPhone.number
  insuranceCompanyPhoneCountryFlagId: string // V2.insuranceCompanyPhone.countryInfo.flagId
  insuranceCompanyPhoneCountryCodeValue: string // V2.insuranceCompanyPhone.countryInfo.countryCodeValue
  insuranceCompanyPhoneCountryTitle: string // V2.insuranceCompanyPhone.countryInfo.title
  insuranceCompanyPhoneCountryCode: string // V2.insuranceCompanyPhone.countryInfo.countryCode
  insuranceCompanyPhoneCountryLangs: Language[] // V2.insuranceCompanyPhone.countryInfo.langs

  dateFirstUsage: string
  name: string
  productNumber: string
  safetyStandard: string
  industrial: boolean
  employer: string
  link: string
  additionalLink: string
  ssn: string
}

export type SafetyInformation = SafetyInformationV3
