import { Stack, Typography } from '@mui/material'

interface BottomInputProps {
  input?: JSX.Element
  title?: string
}
export const BottomInput = (props: BottomInputProps) => {
  const { title, input } = props

  return (
    <Stack alignItems="center" justifyContent="center" flex={1}>
      {input}
      <Typography fontSize={12} color="#ffffff" fontWeight={300}>
        {title}
      </Typography>
    </Stack>
  )
}
