import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const EmployerRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 19v-1.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1C7.542 13.15 8.717 13 10 13c.5 0 .988.025 1.463.075.475.05.937.125 1.387.225l-1.75 1.75a3.007 3.007 0 0 0-.538-.05H10c-1.183 0-2.246.142-3.188.425-.941.283-1.712.592-2.312.925a.97.97 0 0 0-.5.85v.8h6.25l2 2H3Zm12.55-.025c-.133 0-.258-.02-.375-.062a.883.883 0 0 1-.325-.213l-2.05-2.05a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.35 1.35 4.35-4.35a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-5.05 5.05c-.1.1-.208.171-.325.213a1.116 1.116 0 0 1-.375.062ZM10 12c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm0-2c.55 0 1.021-.196 1.413-.588C11.804 9.021 12 8.55 12 8c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 10 6c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 8 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z"
          fill="#1F2937"
        />
      </svg>
    </SvgIcon>
  )
}
