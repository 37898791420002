import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const OrganDonorRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
        {...props}
      >
        <path
          d="M16 12.675c-.117 0-.229-.017-.337-.05a.687.687 0 0 1-.288-.175 49.984 49.984 0 0 1-3.762-3.762C10.538 7.479 10 6.35 10 5.3c0-.933.317-1.717.95-2.35S12.367 2 13.3 2c.533 0 1.033.112 1.5.337.467.225.867.53 1.2.913a3.708 3.708 0 0 1 1.2-.913A3.413 3.413 0 0 1 18.7 2c.933 0 1.717.317 2.35.95S22 4.367 22 5.3c0 1.05-.537 2.18-1.612 3.388a50.122 50.122 0 0 1-3.763 3.762.687.687 0 0 1-.288.175c-.108.033-.22.05-.337.05Zm0-2.375a53.456 53.456 0 0 0 2.738-2.788C19.579 6.587 20 5.85 20 5.3c0-.383-.12-.696-.362-.938C19.396 4.121 19.083 4 18.7 4c-.233 0-.454.046-.662.137-.209.092-.388.23-.538.413L16 6.35l-1.5-1.8a1.44 1.44 0 0 0-.537-.413A1.636 1.636 0 0 0 13.3 4c-.383 0-.696.12-.937.362-.242.242-.363.555-.363.938 0 .55.42 1.287 1.262 2.212A53.456 53.456 0 0 0 16 10.3Zm-2.05 10.1 5.95-1.85a1.14 1.14 0 0 0-.362-.387A.93.93 0 0 0 19 18h-5.05a9.1 9.1 0 0 1-1.075-.05 3.952 3.952 0 0 1-.825-.2l-1.425-.475a.905.905 0 0 1-.575-.5A1.04 1.04 0 0 1 10 16a.956.956 0 0 1 .488-.588.97.97 0 0 1 .762-.062l1.05.35c.283.083.604.15.963.2.358.05.845.083 1.462.1H15a.95.95 0 0 0-.162-.525.755.755 0 0 0-.388-.325L8.6 13H7v5.5l6.95 1.9Zm-.525 1.95L7 20.55a1.93 1.93 0 0 1-.787 1.05c-.392.267-.796.4-1.213.4H3c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 20v-7c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 3 11h5.6c.117 0 .233.012.35.037.117.025.225.055.325.088L15.15 13.3c.55.2.996.55 1.338 1.05.341.5.512 1.05.512 1.65h2c.833 0 1.542.275 2.125.825.583.55.875 1.275.875 2.175 0 .367-.096.654-.288.863-.191.208-.487.379-.887.512l-6.25 1.95c-.183.067-.375.1-.575.1-.2 0-.392-.025-.575-.075ZM3 20h2v-7H3v7Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
