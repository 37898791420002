import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CallRounded = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
      {...props}
    >
      <path
        d="M19.95 21c-2.15 0-4.246-.48-6.287-1.438a19.045 19.045 0 0 1-5.425-3.8 19.045 19.045 0 0 1-3.8-5.425C3.479 8.296 3 6.2 3 4.05c0-.3.1-.55.3-.75.2-.2.45-.3.75-.3H8.1a.96.96 0 0 1 .625.225.88.88 0 0 1 .325.575l.65 3.5c.033.233.03.446-.012.637a1.027 1.027 0 0 1-.288.513L6.975 10.9c.7 1.2 1.58 2.325 2.638 3.375A18.626 18.626 0 0 0 13.1 17l2.35-2.35c.15-.15.346-.263.588-.338.241-.075.479-.095.712-.062l3.45.7c.233.05.425.162.575.337.15.175.225.38.225.613v4.05c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)

export default CallRounded
