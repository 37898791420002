import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const MedicalInformationRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
        {...props}
      >
        <path
          d="M7 16v1c0 .283.096.52.287.712.192.192.43.288.713.288a.968.968 0 0 0 .713-.288A.967.967 0 0 0 9 17v-1h1a.968.968 0 0 0 .713-.288A.967.967 0 0 0 11 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 10 14H9v-1a.97.97 0 0 0-.287-.713A.97.97 0 0 0 8 12a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 13v1H6a.97.97 0 0 0-.713.287A.97.97 0 0 0 5 15c0 .283.096.52.287.712.192.192.43.288.713.288h1Zm11.25-1.5a.728.728 0 0 0 .75-.75.731.731 0 0 0-.212-.538.731.731 0 0 0-.538-.212h-4.5a.728.728 0 0 0-.75.75.726.726 0 0 0 .75.75h4.5Zm-2 3c.217 0 .396-.07.538-.212A.731.731 0 0 0 17 16.75a.731.731 0 0 0-.212-.538.731.731 0 0 0-.538-.212h-2.5a.728.728 0 0 0-.75.75.728.728 0 0 0 .75.75h2.5ZM15 7h5c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 22H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 7h5V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 11 2h2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3Zm-4 2h2V4h-2v5ZM9 9H4v11h16V9h-5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 11h-2c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 9 9Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
