import { SafetyInformation } from '../types'

export const expandPayloadV2 = (url: string) => {
  const payload = url.split('payload=')[1]
  const decodedPayload = decodeURIComponent(payload)
  const obj = JSON.parse(decodedPayload)

  const expanded: SafetyInformation = {
    birthDate: obj[0],
    emergencyContact1Name: obj[1],
    emergencyContact2Name: obj[2],
    emergencyContact3Name: obj[3],
    emergencyContact1PhoneNumber: obj[4]?.number ?? '',
    emergencyContact2PhoneNumber: obj[5]?.number ?? '',
    emergencyContact3PhoneNumber: obj[6]?.number ?? '',
    emergencyContact1PhoneCountryFlagId: obj[4]?.countryInfo?.flagId ?? '',
    emergencyContact2PhoneCountryFlagId: obj[5]?.countryInfo?.flagId ?? '',
    emergencyContact3PhoneCountryFlagId: obj[6]?.countryInfo?.flagId ?? '',
    emergencyContact1PhoneCountryCodeValue:
      obj[4]?.countryInfo?.countryCodeValue ?? '',
    emergencyContact2PhoneCountryCodeValue:
      obj[5]?.countryInfo?.countryCodeValue ?? '',
    emergencyContact3PhoneCountryCodeValue:
      obj[6]?.countryInfo?.countryCodeValue ?? '',
    emergencyContact1PhoneCountryTitle: obj[4]?.countryInfo?.title ?? '',
    emergencyContact2PhoneCountryTitle: obj[5]?.countryInfo?.title ?? '',
    emergencyContact3PhoneCountryTitle: obj[6]?.countryInfo?.title ?? '',
    emergencyContact1PhoneCountryCode: obj[4]?.countryInfo?.countryCode,
    emergencyContact2PhoneCountryCode: obj[5]?.countryInfo?.countryCode ?? '',
    emergencyContact3PhoneCountryCode: obj[6]?.countryInfo?.countryCode ?? '',
    emergencyContact1PhoneCountryLangs: obj[4]?.countryInfo?.langs ?? [],
    emergencyContact2PhoneCountryLangs: obj[5]?.countryInfo?.langs ?? [],
    emergencyContact3PhoneCountryLangs: obj[6]?.countryInfo?.langs ?? [],
    emergencyContact1Relation: obj[7],
    emergencyContact2Relation: obj[8],
    emergencyContact3Relation: obj[9],
    eyeColor: obj[10],
    firstName: obj[11],
    gender: obj[12],
    height: obj[23] === 'ft/lbs' ? obj[13] + `"` : obj[13], // If we scan with app, we add `"` to height
    identificationCharacteristics: obj[14],
    insuranceCompany: obj[15],
    insuranceCompanyPhoneNumber: obj[16]?.number,
    insuranceCompanyPhoneCountryFlagId: obj[16]?.countryInfo?.flagId ?? '',
    insuranceCompanyPhoneCountryCodeValue:
      obj[16]?.countryInfo?.countryCodeValue ?? '',
    insuranceCompanyPhoneCountryTitle: obj[16]?.countryInfo?.title ?? '',
    insuranceCompanyPhoneCountryCode: obj[16]?.countryInfo?.countryCode ?? '',
    insuranceCompanyPhoneCountryLangs: obj[16]?.countryInfo?.langs ?? [],
    insuranceNumber: obj[17],
    language1Name: obj[18]?.name,
    language2Name: obj[19]?.name,
    language3Name: obj[20]?.name,
    language1Native: obj[18]?.native,
    language2Native: obj[19]?.native,
    language3Native: obj[20]?.native,
    lastName: obj[21],
    medicalHistory: obj[22],
    metric: obj[23],
    nationalityCountryFlagId: obj[24]?.flagId ?? '',
    nationalityCountryCodeValue: obj[24]?.countryCodeValue ?? '',
    nationalityCountryTitle: obj[24]?.title ?? '',
    nationalityCountryCode: obj[24]?.countryCode ?? '',
    nationalityCountryLangs: obj[24]?.langs ?? [],
    organDonor: obj[25],
    pacemaker: obj[26],
    passportNumber: obj[27],
    religion: obj[28],
    updatedAt: obj[29],
    weight: obj[30],

    dateFirstUsage: obj[31],
    name: obj[32],
    productNumber: obj[33],
    safetyStandard: obj[34],
    industrial: false,
    link: '',
    additionalLink: '',
    employer: '',
    ssn: '',
    fullName: '',
  }

  return expanded
}
