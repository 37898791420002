import Stack from '@mui/material/Stack'

import { SafetyInformation } from '../types'
import { DisplayText } from './DisplayText'
import { EmergencyContactRounded } from '../assets/icons/EmergencyContactRounded'
import { CallContact } from './CallContact'

interface PageProps {
  safetyInformation: SafetyInformation
}

export const Page2 = ({ safetyInformation }: PageProps) => {
  return (
    <Stack margin={3} spacing={3}>
      <DisplayText
        icon={EmergencyContactRounded}
        title={'FIRST EMERGENCY CONTACT'}
        value={[
          safetyInformation.emergencyContact1Name,
          safetyInformation.emergencyContact1Relation,
        ]}
        customElement={
          <CallContact
            countryCode={safetyInformation.emergencyContact1PhoneCountryCode}
            mobileNumber={safetyInformation.emergencyContact1PhoneNumber}
          />
        }
      />
      <DisplayText
        icon={EmergencyContactRounded}
        title={'SECOND EMERGENCY CONTACT'}
        value={[
          safetyInformation.emergencyContact2Name,
          safetyInformation.emergencyContact2Relation,
        ]}
        customElement={
          <CallContact
            countryCode={safetyInformation.emergencyContact2PhoneCountryCode}
            mobileNumber={safetyInformation.emergencyContact2PhoneNumber}
          />
        }
      />
    </Stack>
  )
}
