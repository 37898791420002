import { Buffer } from 'buffer'
import { unzip } from 'gzip-js'
import { SafetyInformation } from '../types'

const decodeData = (data: string) => {
  const gzipped = Buffer.from(data, 'base64')
  const unzipped = unzip(gzipped)
  const result = Buffer.from(unzipped).toString('utf8')
  const parsedObject = JSON.parse(result)

  return parsedObject
}

export const expandPayloadV3 = (searchParams: URLSearchParams) => {
  const payload = searchParams.get('payload') ?? ''
  const obj = decodeData(payload)

  const expanded: SafetyInformation = {
    birthDate: obj[0],
    emergencyContact1Name: obj[1],
    emergencyContact2Name: obj[2],
    emergencyContact3Name: obj[3],
    emergencyContact1Relation: obj[4],
    emergencyContact2Relation: obj[5],
    emergencyContact3Relation: obj[6],
    emergencyContact1PhoneNumber: obj[7],
    emergencyContact2PhoneNumber: obj[8],
    emergencyContact3PhoneNumber: obj[9],
    emergencyContact1PhoneCountryFlagId: obj[10],
    emergencyContact2PhoneCountryFlagId: obj[11],
    emergencyContact3PhoneCountryFlagId: obj[12],
    emergencyContact1PhoneCountryCodeValue: obj[13],
    emergencyContact2PhoneCountryCodeValue: obj[14],
    emergencyContact3PhoneCountryCodeValue: obj[15],
    emergencyContact1PhoneCountryTitle: obj[16],
    emergencyContact2PhoneCountryTitle: obj[17],
    emergencyContact3PhoneCountryTitle: obj[18],
    emergencyContact1PhoneCountryCode: obj[19],
    emergencyContact2PhoneCountryCode: obj[20],
    emergencyContact3PhoneCountryCode: obj[21],
    emergencyContact1PhoneCountryLangs: obj[22],
    emergencyContact2PhoneCountryLangs: obj[23],
    emergencyContact3PhoneCountryLangs: obj[24],
    eyeColor: obj[25],
    firstName: obj[26],
    lastName: obj[27],
    gender: obj[28],
    metric: obj[29],
    height: obj[29] === 'ft/lbs' ? obj[30] + `"` : obj[30], // If we scan with app, we add `"` to height
    weight: obj[31],
    identificationCharacteristics: obj[32],
    insuranceNumber: obj[33],
    insuranceCompany: obj[34],
    insuranceCompanyPhoneNumber: obj[35],
    insuranceCompanyPhoneCountryFlagId: obj[36],
    insuranceCompanyPhoneCountryCodeValue: obj[37],
    insuranceCompanyPhoneCountryTitle: obj[38],
    insuranceCompanyPhoneCountryCode: obj[39],
    insuranceCompanyPhoneCountryLangs: obj[40],
    language1Name: obj[41],
    language2Name: obj[42],
    language3Name: obj[43],
    language1Native: obj[44],
    language2Native: obj[45],
    language3Native: obj[46],
    medicalHistory: obj[47],
    nationalityCountryFlagId: obj[48],
    nationalityCountryCodeValue: obj[49],
    nationalityCountryTitle: obj[50],
    nationalityCountryCode: obj[51],
    nationalityCountryLangs: obj[52],
    organDonor: obj[53],
    pacemaker: obj[54],
    passportNumber: obj[55],
    religion: obj[56],
    updatedAt: obj[57],
    dateFirstUsage: obj[58],
    name: obj[59],
    productNumber: obj[60],
    safetyStandard: obj[61],
    industrial: obj[62] ?? false,
    link: obj[64] ?? '',
    additionalLink: obj[65] ?? '',
    employer: obj[63] ?? '',
    ssn: obj[66] ?? '',
    fullName: obj[67],
  }
  return expanded
}
