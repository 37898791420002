import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const PassportRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
        {...props}
      >
        <path
          d="M17.25 13.5a.728.728 0 0 0 .75-.75.731.731 0 0 0-.212-.538.731.731 0 0 0-.538-.212h-2.5a.728.728 0 0 0-.75.75.726.726 0 0 0 .75.75h2.5Zm0 3c.217 0 .396-.07.538-.212A.731.731 0 0 0 18 15.75a.731.731 0 0 0-.212-.538.731.731 0 0 0-.538-.212h-2.5a.728.728 0 0 0-.75.75.728.728 0 0 0 .75.75h2.5ZM15 7h5c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 22H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 7h5V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 11 2h2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3Zm-4 2h2V4h-2v5Zm-2 6c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 9 12c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 7.5 13.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438Zm-3 3h6v-.45c0-.283-.08-.546-.238-.788a1.568 1.568 0 0 0-.662-.562 5.14 5.14 0 0 0-1.012-.337A5.05 5.05 0 0 0 9 15.75c-.383 0-.746.038-1.087.113-.342.075-.68.187-1.013.337-.283.133-.504.32-.662.562A1.41 1.41 0 0 0 6 17.55V18Zm3-9H4v11h16V9h-5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 11h-2c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 9 9Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
