import { Box, IconButton, Link, Stack, Typography } from '@mui/material'
import { BottomInput } from './BottomHeaderComponent'
import LogotypeText from '../assets/icons/LogotypeText'
import { useState } from 'react'

interface HeaderProps {
  fullName: string
  nativeLanguage: string
  secondaryLanguage: string
  birthDate: string
  lastEdited: string
}

export const Header = ({
  fullName,
  nativeLanguage,
  secondaryLanguage,
  birthDate,
  lastEdited,
}: HeaderProps) => {
  const noValueSet = 'No value set'

  const [showInfoBar, setShowInfoBar] = useState(true)
  let languages = noValueSet
  if (nativeLanguage) {
    languages = nativeLanguage
  }
  if (nativeLanguage && secondaryLanguage) {
    languages += `/${secondaryLanguage}`
  } else if (secondaryLanguage) {
    languages = secondaryLanguage
  }

  return (
    <Box
      style={{
        background: 'var(--color-main-pitch-black)',
        alignItems: 'center',
        padding: 24,
        paddingBottom: 44,
        flex: 1,
      }}
    >
      {showInfoBar && (
        <Box
          style={{
            background: 'var(--color-main-unnatural-green)',
            alignItems: 'center',
            padding: 20,
            paddingRight: 8,
            margin: '0 -24px',
            marginBottom: 30,
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              lineHeight: '100%',
              flex: 1,
              marginRight: 8,
            }}
          >
            Please note that this information is read on your smartphone only
            and not uploaded to the web.{' '}
            <Link
              href="https://twiceme.com/support/#1683790397871-5884dd8c-db6f"
              target="blank"
              style={{ textDecoration: 'underline' }}
            >
              Read more
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                width={16}
                height={16}
                color="var(--color-main-pitch-black)"
                data-testid="ArrowOutwardIcon"
              >
                <path
                  fill="currentColor"
                  d="M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z"
                ></path>
              </svg>
            </Link>
          </Typography>
          <IconButton onClick={() => setShowInfoBar(false)}>
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              width={28}
              height={28}
              data-testid="CloseIcon"
            >
              <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </IconButton>
        </Box>
      )}
      {/* <Stack width={'100%'} alignItems={'flex-end'}>
      </Stack> */}
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        my={2}
        width="100%"
        mb={3}
      >
        <LogotypeText />
      </Stack>

      <Typography
        textAlign={'center'}
        fontFamily="Aeonik-Bold"
        fontSize="30px"
        color="#f9fafb"
        flex={1}
      >
        {fullName || noValueSet}
      </Typography>
      <Typography textAlign={'center'} fontSize={12} color="white" flex={1}>
        {lastEdited ? `Last updated: ${lastEdited}` : noValueSet}
      </Typography>

      <Stack
        flexDirection={'row'}
        justifyContent={'center'}
        paddingTop={3}
        paddingBottom={3}
        // gap={6}
      >
        <BottomInput
          title={'Languages'}
          input={
            <Typography
              fontSize={18}
              fontFamily={'Aeonik-Bold'}
              color="#ffffff"
              style={{
                lineBreak: 'anywhere',
              }}
            >
              {languages}
            </Typography>
          }
        />
        <BottomInput
          title={'Date of Birth'}
          input={
            <Typography
              fontSize={18}
              fontFamily={'Aeonik-Bold'}
              color="#ffffff"
            >
              {birthDate || noValueSet}
            </Typography>
          }
        />
      </Stack>
    </Box>
  )
}
