import Stack from '@mui/material/Stack'

import { DisplayText } from './DisplayText'
import { PassportRounded } from '../assets/icons/PassportRounded'
import { InsuranceRounded } from '../assets/icons/InsuranceRounded'
import { SafetyInformation } from '../types'
import { CallContact } from './CallContact'
import LinkIcon from '../assets/icons/Link'
import { DocumentLink } from './DocumentLink'

interface PageProps {
  safetyInformation: SafetyInformation
}

export const Page3 = ({ safetyInformation }: PageProps) => {
  return (
    <Stack margin={3} spacing={3}>
      <DisplayText
        icon={PassportRounded}
        title={'PASSPORT NUMBER'}
        value={[safetyInformation.passportNumber]}
      />
      <DisplayText
        icon={PassportRounded}
        title={'SSN'}
        value={[safetyInformation.ssn]}
      />
      <DisplayText
        icon={InsuranceRounded}
        title={'INSURANCE INFORMATION'}
        value={[
          safetyInformation.insuranceNumber,
          safetyInformation.insuranceCompany,
        ]}
        customElement={
          <CallContact
            countryCode={safetyInformation.insuranceCompanyPhoneCountryCode}
            mobileNumber={safetyInformation.insuranceCompanyPhoneNumber}
          />
        }
      />
      <Stack margin={0} spacing={1}>
        {(safetyInformation.link || safetyInformation.additionalLink) && (
          <DisplayText
            icon={LinkIcon}
            title={'LINK TO TRAINING RECORDS'}
            value={[]}
          />
        )}
        <DocumentLink link={safetyInformation.link} />
        <DocumentLink link={safetyInformation.additionalLink} />
      </Stack>
    </Stack>
  )
}
