import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const HeightAndWeightRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19 3c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21h-6a.967.967 0 0 1-.712-.287A.968.968 0 0 1 12 20c0-.283.096-.521.288-.713A.967.967 0 0 1 13 19h6V5h-6a.967.967 0 0 1-.712-.287A.968.968 0 0 1 12 4c0-.283.096-.521.288-.713A.967.967 0 0 1 13 3h6Z"
          fill="currentColor"
        />
        <path
          d="M14 12c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 17 9c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 14 6c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 11 9c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 14 12Zm-1.5-2.5a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7.48.48 0 0 1 .7 0 .48.48 0 0 1 0 .7.48.48 0 0 1-.35.15Zm1.5 0a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7.48.48 0 0 1 .7 0 .48.48 0 0 1 0 .7.48.48 0 0 1-.35.15Zm1.5 0a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7.48.48 0 0 1 .7 0 .48.48 0 0 1 0 .7.48.48 0 0 1-.35.15ZM6.5 21a.997.997 0 0 1-.365-.066.845.845 0 0 1-.316-.223l-2.552-2.755A1.004 1.004 0 0 1 3 17.248c0-.28.097-.525.292-.735a.887.887 0 0 1 .68-.289c.276 0 .503.097.68.29l.876.944V6.542l-.9.971a.86.86 0 0 1-.656.289.901.901 0 0 1-.68-.315c-.179-.193-.268-.437-.268-.735 0-.297.09-.542.268-.735L5.819 3.29a.855.855 0 0 1 .316-.224C6.25 3.022 6.37 3 6.5 3s.251.022.365.065a.855.855 0 0 1 .316.224l2.552 2.755c.178.192.267.428.267.708 0 .28-.097.525-.292.735a.887.887 0 0 1-.68.289.887.887 0 0 1-.68-.29l-.876-.944v10.916l.9-.971a.86.86 0 0 1 .656-.289c.259 0 .486.105.68.315.179.192.268.437.268.735 0 .297-.09.542-.268.735L7.181 20.71a.845.845 0 0 1-.316.223A.997.997 0 0 1 6.5 21Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
