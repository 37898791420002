import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { LatitudeRounded } from '../assets/icons/LatitudeRounded'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { LongitudeRounded } from '../assets/icons/LongitudeRounded'
import { Send } from '../assets/icons/Send'

export const CurrentLocation = () => {
  const [permissionState, setPermissionState] = useState<PermissionState>()
  const [loading, setLoading] = useState(false)
  const [position, setPosition] = useState<GeolocationPosition>()
  const [denied, setDenied] = useState(false)

  const { accuracy, latitude, longitude } = useMemo(() => {
    const accuracy = position?.coords?.accuracy ?? 0
    let latitude = (position?.coords?.latitude ?? 0).toFixed(4)
    let longitude = (position?.coords?.longitude ?? 0).toFixed(4)

    latitude =
      latitude.substring(0, latitude.indexOf('.') + 3) +
      ' ' +
      latitude.substring(latitude.indexOf('.') + 3)
    longitude =
      longitude.substring(0, longitude.indexOf('.') + 3) +
      ' ' +
      longitude.substring(longitude.indexOf('.') + 3)

    return {
      accuracy: Math.round(accuracy),
      latitude: latitude.replace('.', '. '),
      longitude: longitude.replace('.', '. '),
    }
  }, [position])

  const requestLocation = useCallback(() => {
    setLoading(true)

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition(position)
        setLoading(false)
      },
      (error) => {
        console.error(error)
        if (error?.code === 1) {
          setDenied(true)
        }
        setLoading(false)
      }
    )
  }, [])

  useEffect(() => {
    if (permissionState === 'granted') {
      requestLocation()
    }
  }, [permissionState, requestLocation])

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          if (result.state === 'denied') {
            setDenied(true)
          }
          setPermissionState(result.state)
          result.onchange = () => {
            setPermissionState(result.state)
            if (result.state === 'denied') {
              setDenied(true)
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [])

  const onSend = useCallback(() => {
    const body = `My current position is:
    
Lat: ${position?.coords?.latitude}
Lon: ${position?.coords?.longitude}

Margin of error: ${accuracy} m
Coordinate system: WGS84

Powered by Twiceme

http://maps.google.com/maps?q=loc:${position?.coords?.latitude},${position?.coords?.longitude}`
    // https://www.google.com/maps/search/?api=1&query=${position?.coords?.latitude},${position?.coords?.longitude}`

    const link = document.createElement('a')
    link.href = 'sms:?&body=' + encodeURIComponent(body)
    link.click()
    link.remove()
    // window.open('sms:?&body=' + encodeURIComponent(body), '_self')
  }, [position, accuracy])

  return (
    <Stack
      sx={{
        px: '20px',
        py: '10px',
        borderRadius: '20px',
        backgroundColor: '#f1f1f1',
      }}
    >
      <Stack direction="row" alignItems={'center'} mb={1}>
        <Typography
          variant="h6"
          fontFamily={'Aeonik-Bold'}
          color={'var(--color-main-pitch-black)'}
          fontSize={18}
        >
          Current Location
        </Typography>
      </Stack>

      {/* Ask for prompt */}
      {!position && permissionState !== 'denied' && !denied && !loading && (
        <Stack direction="column">
          <Typography flex={1.2} mb={2}>
            Please allow location access to view and share your current
            location.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{
              borderRadius: 100,
              flex: 1,
              width: '100%',
              p: 2,
              fontFamily: 'Aeonik-Bold',
              textTransform: 'none',
            }}
            onClick={requestLocation}
          >
            Allow
          </Button>
        </Stack>
      )}

      {(permissionState === 'denied' || denied) && (
        <Stack direction="column">
          <Typography flex={1.2} mb={2}>
            Location services are disabled. Enable in your browser to view and
            share your location.
          </Typography>
        </Stack>
      )}

      {/* Loading state */}
      {loading && (
        <Stack direction="row" alignItems={'center'} mb={2}>
          <CircularProgress size={20} />
          <Typography ml={2}>Fetching your location</Typography>
        </Stack>
      )}

      {/* Show coordinates etc */}
      {position && !loading && (
        <>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Stack direction="column" spacing={1} mr={2}>
              <Stack direction="row" spacing={1}>
                <LatitudeRounded />
                <Typography fontFamily={'Aeonik-Bold'}>Lat:</Typography>
                <Typography>{latitude}</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <LongitudeRounded />
                <Typography fontFamily={'Aeonik-Bold'}>Lon:</Typography>
                <Typography>{longitude}</Typography>
              </Stack>
            </Stack>
            <Button
              color="primary"
              variant="contained"
              sx={{
                ml: 4,
                borderRadius: 100,
                flex: 1,
                width: '100%',
                p: 1.4,
                textTransform: 'none',
                fontFamily: 'Aeonik-Bold',
              }}
              onClick={onSend}
              startIcon={
                <Send
                  width={10}
                  height={10}
                  style={{
                    color: 'var(--color-main-unnatural-green',
                    marginTop: 4,
                  }}
                />
              }
            >
              Send
            </Button>
          </Stack>
          <Typography fontSize={12} color={'var(--color-main-gray)'} mt={1.5}>
            Margin of error: {accuracy} m
          </Typography>
        </>
      )}
    </Stack>
  )
}
