import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const InsuranceRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/Svg"
        {...props}
      >
        <path
          d="M12 15.5c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062v-1h1c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 14.5 10h-1V9c0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 12 7.5c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 10.5 9v1h-1c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 8 11.5c0 .417.146.77.438 1.062.291.292.645.438 1.062.438h1v1c0 .417.146.77.438 1.062.291.292.645.438 1.062.438Zm0 6.425h-.25a.497.497 0 0 1-.225-.05c-2.183-.683-3.983-2.038-5.4-4.063C4.708 15.787 4 13.55 4 11.1V6.375c0-.417.121-.792.363-1.125.241-.333.554-.575.937-.725l6-2.25a2.07 2.07 0 0 1 .7-.125c.233 0 .467.042.7.125l6 2.25c.383.15.696.392.938.725.241.333.362.708.362 1.125V11.1c0 2.45-.708 4.687-2.125 6.712-1.417 2.025-3.217 3.38-5.4 4.063-.083.033-.242.05-.475.05Zm0-2.025c1.733-.55 3.167-1.65 4.3-3.3s1.7-3.483 1.7-5.5V6.375l-6-2.25-6 2.25V11.1c0 2.017.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
