import Stack from '@mui/material/Stack'

import { SafetyInformation } from '../types'
import { PersonRounded } from '../assets/icons/PersonRounded'
import { DisplayText } from '../components/DisplayText'
import { LanguageRounded } from '../assets/icons/LanguageRounded'
import { MedicalInformationRounded } from '../assets/icons/MedicalInformationRounded'
import { PacemakerRounded } from '../assets/icons/PacemakerRounded'
import { OrganDonorRounded } from '../assets/icons/OrganDonorRounded'
import { HeightAndWeightRounded } from '../assets/icons/HeightAndWeightRounded'
import { EyeRounded } from '../assets/icons/EyeRounded'
import { NationalityRounded } from '../assets/icons/NationalityRounded'
import { ReligionRounded } from '../assets/icons/ReligionRounded'
import { MetricSystem } from '../types'

interface PageProps {
  safetyInformation: SafetyInformation
}

const nullableBooleanToString = (value: boolean | null) => {
  if (value === null || value === undefined) {
    return 'No value set'
  }
  return value ? 'Yes' : 'No'
}

export const Page1 = ({ safetyInformation }: PageProps) => {
  const hUnit = safetyInformation.metric === MetricSystem.metric ? 'cm' : 'ft'
  const wUnit = safetyInformation.metric === MetricSystem.metric ? 'kg' : 'lb'

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <Stack margin={3} spacing={3}>
      <DisplayText
        icon={MedicalInformationRounded}
        title={'MEDICAL INFORMATION'}
        value={[safetyInformation.medicalHistory]}
      />
      <DisplayText
        icon={PacemakerRounded}
        title={'PACEMAKER'}
        value={[nullableBooleanToString(safetyInformation.pacemaker)]}
      />
      <DisplayText
        icon={OrganDonorRounded}
        title={'ORGAN DONOR'}
        value={[nullableBooleanToString(safetyInformation.organDonor)]}
      />
      <DisplayText
        icon={MedicalInformationRounded}
        title={'IDENTIFICATION CHARACTERISTICS'}
        value={[safetyInformation.identificationCharacteristics]}
      />
      <DisplayText
        icon={HeightAndWeightRounded}
        title={'HEIGHT & WEIGHT'}
        value={[
          `${safetyInformation.height} ${hUnit}`,
          `${safetyInformation.weight} ${wUnit}`,
        ]}
      />
      <DisplayText
        icon={EyeRounded}
        title={'EYE COLOR'}
        value={[capitalize(safetyInformation.eyeColor)]}
      />
      <DisplayText
        icon={PersonRounded}
        title={'GENDER'}
        value={[capitalize(safetyInformation.gender)]}
      />
      <DisplayText
        icon={NationalityRounded}
        title={'NATIONALITY'}
        value={[safetyInformation.nationalityCountryTitle]}
      />
      <DisplayText
        icon={ReligionRounded}
        title={'RELIGION'}
        value={[safetyInformation.religion]}
      />
      <DisplayText
        title={'SECONDARY LANGUAGE'}
        icon={LanguageRounded}
        value={[safetyInformation.language2Name]}
      />
    </Stack>
  )
}
