import { Stack, Typography } from '@mui/material'
import OpenLink from '../assets/icons/OpenLink'

interface DocumentLinkProps {
  link: string
}

export const DocumentLink = ({ link }: DocumentLinkProps) => {
  // const isURL = (str: string) => {
  //   return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
  //     str
  //   )
  // }
  const openInNewTab = (url: string) => {
    const updatedUrl =
      url.toLocaleLowerCase().startsWith('https') ||
      url.toLocaleLowerCase().startsWith('http')
        ? url
        : `https://${url}`

    if (!url) return
    const newWindow = window.open(updatedUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return link ? (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{
        backgroundColor: 'var(--color-main-mist)',
        padding: '12px 16px',
        borderRadius: '20px',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        openInNewTab(link)
      }}
    >
      <Typography
        fontSize="14px"
        color="var(--color-main-pitch-black)"
        sx={{
          backgroundColor: 'var(--color-main-mist)',
          fontFamily: 'Aeonik-Medium',
          textDecoration: link ? 'underline' : 'none',
        }}
      >
        {link}
      </Typography>
      {link && <OpenLink />}
    </Stack>
  ) : null
}
