import React from 'react'
import ReactDOM from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material'

import App from './App'
import reportWebVitals from './reportWebVitals'

import '@fontsource/lato/300.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import './assets/index.css'

const theme = createTheme({
  typography: {
    fontFamily: 'Aeonik-Regular',
  },
  shadows: ['none'],
  palette: {
    contrastThreshold: 2,
    primary: {
      main: '#231F20',
    },
    secondary: {
      main: '#00bfa5',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
