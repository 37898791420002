import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const ReligionRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12 8c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 8ZM6.8 20c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 5 18.2c0-.35.1-.68.3-.988.2-.308.467-.529.8-.662L10 15v-2.25c-.8.917-1.7 1.646-2.7 2.188-1 .541-2.083.879-3.25 1.012a.872.872 0 0 1-.738-.25A1.027 1.027 0 0 1 3 14.925c0-.25.088-.467.263-.65.175-.183.395-.292.662-.325a6.707 6.707 0 0 0 2.563-.85A8.077 8.077 0 0 0 8.6 11.3l1.35-1.6c.2-.233.433-.408.7-.525.267-.117.55-.175.85-.175h1c.3 0 .583.058.85.175.267.117.5.292.7.525l1.35 1.6a8.07 8.07 0 0 0 2.113 1.8c.775.45 1.629.733 2.562.85.267.033.488.146.663.337a.956.956 0 0 1 .262.663c0 .3-.104.55-.312.75a.872.872 0 0 1-.738.25 8.877 8.877 0 0 1-3.25-1.012c-1-.542-1.9-1.271-2.7-2.188V15l3.9 1.55c.333.133.6.354.8.662.2.309.3.638.3.988 0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H10v-.5c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425h3c.15 0 .271-.046.363-.137A.493.493 0 0 0 15 17.5a.493.493 0 0 0-.137-.363A.493.493 0 0 0 14.5 17h-3c-.7 0-1.292.242-1.775.725C9.242 18.208 9 18.8 9 19.5v.5H6.8Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
