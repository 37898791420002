import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
export const LinkIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width={11}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1h9.07v9.043M10.07 1 1.293 9.752"
        stroke="#231F20"
        strokeWidth={1.388}
      />
    </svg>
  </SvgIcon>
)

export default LinkIcon
