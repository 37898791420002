import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SafetyStandardRounded = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
      {...props}
    >
      <path
        d="m10.95 12.7-1.375-1.4c-.2-.2-.437-.3-.712-.3-.275 0-.513.1-.713.3-.2.2-.304.437-.312.712a.93.93 0 0 0 .287.713l2.125 2.125c.2.2.433.3.7.3.267 0 .5-.1.7-.3l4.25-4.25c.2-.2.3-.438.3-.713 0-.275-.1-.512-.3-.712-.2-.2-.437-.3-.712-.3-.275 0-.513.1-.713.3L10.95 12.7ZM12 21.925h-.25a.497.497 0 0 1-.225-.05c-2.183-.683-3.983-2.038-5.4-4.063C4.708 15.787 4 13.55 4 11.1V6.375c0-.417.121-.792.363-1.125.241-.333.554-.575.937-.725l6-2.25a2.07 2.07 0 0 1 .7-.125c.233 0 .467.042.7.125l6 2.25c.383.15.696.392.938.725.241.333.362.708.362 1.125V11.1c0 2.45-.708 4.687-2.125 6.712-1.417 2.025-3.217 3.38-5.4 4.063-.083.033-.242.05-.475.05Zm0-2.025c1.733-.55 3.167-1.65 4.3-3.3s1.7-3.483 1.7-5.5V6.375l-6-2.25-6 2.25V11.1c0 2.017.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)
