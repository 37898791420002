import { Link, Stack, Typography } from '@mui/material'
import CallRounded from '../assets/icons/CallRounded'
import { CountriesDropdownItem } from '../types'
interface CallContactProps {
  countryCode: CountriesDropdownItem['countryCode']
  mobileNumber: string
}

export const CallContact = ({
  countryCode,
  mobileNumber,
}: CallContactProps) => {
  return (
    <Link
      href={mobileNumber ? `tel:${countryCode}${mobileNumber}` : ''}
      style={{ textDecoration: 'none' }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          backgroundColor: 'var(--color-main-mist)',
          padding: '12px 16px',
          borderRadius: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="14px"
          color="#1f2937"
          fontFamily={'Aeonik-Medium'}
        >
          {`${countryCode} | ${mobileNumber}`}
        </Typography>
        {mobileNumber && <CallRounded />}
      </Stack>
    </Link>
  )
}
