import { Button, IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { CloseRounded } from '../assets/icons/CloseRounded'
import AppStore from '../assets/images/Appstore.png'
import PlayStore from '../assets/images/Playstore.png'

type Props = {
  visible: boolean
  onClick: () => void
}

export const DownloadApp = (props: Props) => {
  const { onClick, visible } = props
  const onClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=twiceme.myiceinfo.org'
    )
  }
  const onClickAppStore = () => {
    window.open('https://apps.apple.com/us/app/twiceme/id1443727990')
  }

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 3,
        borderTopColor: '#D1D5DB',
        borderTopWidth: 0.1,
        backgroundColor: '#F9FAFB',
        padding: 8,
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      <Stack gap={1} marginBottom={1}>
        <IconButton
          onClick={onClick}
          style={{ alignSelf: 'flex-end', marginRight: 8, color: 'black' }}
        >
          <CloseRounded />
        </IconButton>

        <Typography fontSize={18} fontWeight={'bold'} textAlign={'center'}>
          Download the Twiceme app
        </Typography>

        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'center'}
          gap={2}
        >
          <Button
            onClick={onClickAppStore}
            style={{ backgroundColor: '#F9FAFB' }}
          >
            <img src={AppStore} alt="appstore" />
          </Button>
          <Button
            onClick={onClickPlayStore}
            style={{ backgroundColor: '#F9FAFB' }}
          >
            <img src={PlayStore} alt="playstore" />
          </Button>
        </Stack>
      </Stack>
    </div>
  )
}
