import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const Send = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 -960 960 960"
        fill="currentColor"
      >
        <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
      </svg>
    </SvgIcon>
  )
}
